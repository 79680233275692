
body {
  font-family: 'Oswald', sans-serif;;
  background-color: #14436b !important;
  color: white;
}

.dane-scozzari {
  justify-content: center;
}

.font-white {
  color: white;
}

h1 {
  font-family: "Fredericka the Great";
  font-size: 100px !important;
}

h2 {
  color: #3046c5;
}

header h2 {
  color: #cec8ff;
}

header a {
  color: white;
}

a:visited {
  color: white;
}

a:focus {
  color: white;
}

a:hover {
  color: white;
}

a:active {
  color: white;
}

h3 {
  font-family:'Fredericka the Great', cursive;
  color: white;
  font-size: 50px !important;
  text-align: center;
  position: relative;
  top: 20px;
  background-color: rgba(0, 0, 0, 0.5);
}

p {
  color: white;
  font-size: 20px;
  position: relative;
  top: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  font-family: "Oswald", sans-serif;
}

.site-footer {
  background-color: #14436b;
  padding: 20px 0;
  position: relative;
  top: 20px;
}

.jumbotron {
  margin: 0 !important;
  padding: 0;
  background-color: #14436b !important;
  color: white;
  text-align: center;
}

.navbar {
  background-color: #14436b;
  color: white;
  font-family: "Oswald", sans-serif;
}

.tab-content {
  border: 1px solid #ddd;
  border-top: none;
  padding: 10px;
}


.img-fluid {
  max-width: 100%;
  height: auto;
}

.home-pic {
  max-width: 100%;
  height: auto;
  opacity: .7;
}


.breadcrumb {
  background: none;
}

.card {
  position: relative;
  background: none;
  border: none !important;
  color: white;
  font-family: "Oswald", sans-serif;
}
.card-header {
  background-color: #14436b !important;
  padding-bottom: 25px !important;

}
.card-body {
  position: relative;
  background: none;
  padding: 5px !important;
  background-color: #14436b;
}

.form-group {
  color: white;
  position: relative;
  top: 20px;
  font-family: "Oswald", sans-serif;
}

#form-submit {
  position: relative;
  top: 20px;
  left: 20px;
  margin-bottom: 10px;
}

.btn-link {
  font-family: "Oswald", sans-serif;
}

.btn-spotify {
  background-color: #1db954 !important;
}
.modal-body {
  background-color: #14436b;
}

.modal-header {
  background-color: #14436b;
  color: white;
  font-family: "Oswald", sans-serif;
}

header {
  color: white;
  font-size: 20px;
  margin-left: 150px;
}

#bg-img {
  background-size: cover;
}

.form-submission {
  color: #14436b;
  font-size: 40px;
  padding-top: 200px;
  padding-bottom: 200px;
  text-align: center;
  font-family: "Oswald", sans-serif;
  


}
@media only screen and (max-width: 576px) {
  .navbar-text {
    display: none !important;
  }
  h1 {
    font-size: 80px;
  }
}

.temp-gig {

  text-align: center;
  font-size: 30px;
  margin-top: 40px;

}

.p-3 {
  text-align: center;
}

.drop-down {
  background-color: #14436b !important;
  border-color: white !important;
}

.carousel {
  text-align: center;
}
.carousel-img {
  width: 75%;
  
}